import { useState } from "react";
import "./App.css";
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const navigation = [
  { name: 'الرئيسية - مجموعة تأمين قطري ', href: '/' }
]

function App() {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">
      <header className="inset-x-0 top-0 z-50" >
        <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                className="h-8 w-auto"
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
        </nav>
        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  alt=""
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  className="h-8 w-auto"
                />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root" dir="rtl">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>

      <div className="container mx-auto p-4 text-right" dir="rtl">
        <h1 className="text-2xl font-bold mb-4">أفضل شركة تأمين في قطر</h1>

        <div className="mb-4">
          <img src="/images/1.webp" className="w-full cursor-pointer" onClick={() => { }} />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <img src="/images/2.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
          <div>
            <img src="/images/3.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <img src="/images/4.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
          <div>
            <img src="/images/5.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>

          <div>
            <img src="/images/6.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
          <div>
            <img src="/images/7.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>

          <div>
            <img src="/images/8.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
          <div>
            <img src="/images/9.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <div>
            <img src="/images/10.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
        </div>


        <div className="grid grid-cols-1 gap-4">
          <div>
            <img src="/images/12.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <div>
            <img src="/images/13.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <div>
            <img src="/images/14.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
        </div>

        <h1 className="text-2xl font-bold mb-4">نفتخر بثقة أكثر من 200,000 عميل في قطر</h1>

        <div className="grid grid-cols-1 gap-4">
          <div>
            <img src="/images/15.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <div>
            <img src="/images/16.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <div>
            <img src="/images/17.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <div>
            <img src="/images/18.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <div>
            <img src="/images/19.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <div>
            <img src="/images/20.webp" className="w-full cursor-pointer" onClick={() => { }} />
          </div>
        </div>

        <h2 className="text-xl font-bold mb-4">الدعم</h2>

        <div className="border-t border-gray-200 my-4"></div>

        <div className="space-y-3 mb-6">
          <button className="w-full py-2 px-4 border rounded-lg flex items-center justify-between">
            <span>إستفسارات عامة</span>
            <span className="text-gray-500">?</span>
          </button>
          <button className="w-full py-2 px-4 border rounded-lg text-right">
            التأمين الصحي إلزامي
          </button>
        </div>

        <h3 className="text-lg font-semibold mb-2">التأمينات</h3>

        <div className="border-t border-gray-200 my-4"></div>

        <ul className="space-y-2 text-gray-600">
          <li>السيارة</li>
          <li>المسؤولية ضد الغير</li>
          <li>شامل</li>
          <li>السفر</li>
        </ul>
      </div>
    </div>
  );
}

export default App;
